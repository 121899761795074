<template>
  <!--# BR#26 - mbeng atemson - 23/1/2025 - icmtrader crpto popup -->
  <div v-if="isVisible" class="modal-overlay">
  <div class="modal">
    <header class="modal-header">
      <h2 style="font-size: 24px; color: #06467A;">Make the deposit</h2>
      <button class="close-button" @click="closeModal">×</button>
    </header>
    <div class="modal-content">
      <div class="alert-box">
        <strong>Attention!</strong> Only send Tether (TRC20) assets to this address. Other assets will be lost forever.
      </div>
      <div class="details">
        <div class="heading">
          <img :src="require('@/assets/icons/usdttrc20.svg')" alt="icon" class="icon" />
          <p style="font-size: 20px; font-weight: bold;">USDTTRC20 (USDTTRC20) Address</p>
        </div>
        <div class="qr-code-container">
          <img 
            :src="require('@/assets/icmtrader-usdt1.png') ? require('@/assets/icmtrader-usdt1.png') : paymentRequest.qr_code_url" 
            alt="QR Code" 
            @click="showZoomModal"
            style="cursor: pointer; max-width: 100%;"
          />
           <!-- Zoom Modal -->
          <div v-if="isZoomed" class="zoom-modal" @click="closeZoomModal">
            <img
              :src="require('@/assets/icmtrader-usdt1.png') ? require('@/assets/icmtrader-usdt1.png') : paymentRequest.qr_code_url"
              alt="Zoomed QR Code"
              class="zoomed-image"
            /></div>
      </div>
        <p class="address" style="color: #06467A;">
          TFF6DSpWtsmkBj2rk7c2cuaLnL72KRL5Vs
          <button @click="copyAddress" class="btn btn:hover">📋</button>
        </p>
        <p>
           <!-- {{ amount }} USD -->
          <!-- <strong>Amount:</strong> {{ amount }} {{ paymentRequest.coin.name }} -->
        </p>
        <p>
           {{ convertToTRC20(amount) }} USDTTRC20 ≈  {{ amount }}.00 USD
        </p>
       
      </div>
      <div class="important-message">
        <p><strong>Important:</strong> Once your transfer is complete, please ensure you send the transaction ID to <a href="mailto:support@icmtrader.com" style="color: blue; text-decoration: underline;">support@icmtrader.com</a> for confirmation.</p>
        
        <p class="translation">
          لطفا توجه ویژه داشته باشید: پس از انجام تراکنش، هش آن را به ادرس ایمیل <a href="mailto:support@icmtrader.com" style="color: blue; text-decoration: underline;">support@icmtrader.com</a> ارسال نمایید. این کار برای تکمیل مراحل ضروری است.
        </p>
      </div>
       <br>
      <div style="display: flex; justify-content: center; align-items: center;">
             <button @click="closeModal" class="footer-button">Close</button>
      </div>
    </div>
    <footer class="modal-footer">
      <p>© {{ currentYear }} ICM Trader</p> 
    </footer>
  </div>
</div>
</template>

<script>
export default {
props: {
  // isVisible: {
  //   type: Boolean,
  //   required: true,
  // },
  paymentRequest: {
    type: Object,
    required: true,
    default: () => ({
      address: '',
      amount: 0,
      coin: '' ,
      qr_code_url: '',
    }),
  },
    isVisible: { type: Boolean, required: true },
   
    
    
},
data() {
  return {
    
    isModalOpen: false,
    isVisible: false,
    isZoomed: false,
    amount: this.$route.query.amount || 0,
    currentYear: new Date().getFullYear(),
  };
},
methods: {
  closeModal() {
    this.isModalOpen = false;
    this.isVisible = false;
    window.close()
  },
  copyAddress() {
    navigator.clipboard.writeText("TFF6DSpWtsmkBj2rk7c2cuaLnL72KRL5Vs");
    alert("Address copied to clipboard!");
  },
  convertToTRC20(amount) {
    const conversionRate = 1; // Exchange rate: 1 USD = 1.01960769 USDT
    return (amount / conversionRate).toFixed(8); // Returns the value with 8 decimal places
  },
  showZoomModal() {
    this.isZoomed = true; 
  },
  closeZoomModal() {
    this.isZoomed = false; 
  },
},

};
</script>

<style scoped>
.modal-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5);
display: flex;
align-items: center;
justify-content: center;
z-index: 1000;
}

.modal {
background: #fff;
border-radius: 8px;
padding: 0;
width: 600px;
max-width: 90%;
box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
position: relative;
}

.modal-header {
display: flex;
justify-content: space-between;
align-items: center;
padding: 15px 20px;
border-bottom: 1px solid #ddd;
background-color: #f8f9fa;
}

.modal-header h2 {
margin: 0;
font-size: 18px;
font-weight: bold;
}

.close-button {
background: none;
border: none;
font-size: 1.5rem;
cursor: pointer;
color: #333;
}

.close-button:hover {
color: #d8000c;
}

.modal-content {
padding: 20px;
background: #fff;
}

.alert-box {
background: #f8d7da;
color: #842029;
padding: 10px;
margin-bottom: 15px;
border: 1px solid #842029;
border-radius: 5px;
font-size: 14px;
}

.details {
text-align: center;
}


.qr-code-container img {
max-width: 100%;
height: auto;
display: block;
margin: 0 auto;
}

.address {
font-family: monospace;
font-size: 16px;
margin-bottom: 10px;
}

.important-message {
margin-top: 10px;
font-size: 14px;
text-align: left;
color: #212529;
}

.translation {
margin-top: 10px;
font-size: 14px;
text-align: left;
color: #212529;
}

.modal-footer {
padding: 15px 20px;
border-top: 1px solid #ddd;
text-align: center;
background-color: #f8f9fa;
}

.footer-button {
padding: 8px 15px;
font-size: 14px;
color: white;
background-color: #007bff;
border: none;
border-radius: 5px;
cursor: pointer;
}

.footer-button:hover {
background-color: #0056b3;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  vertical-align: middle;
  border-radius: 50%; /* Makes the image circular */
  background-color: #1f5c1f; /* Light green background */
  overflow: hidden; /* Ensures any excess content stays within the circle */
}

.details .heading {
display: flex;
align-items: center; /* Aligns items vertically in the center */
gap: 8px; /* Adds space between the icon and the heading */
}

.details .heading .icon {
width: 50px; /* Adjust size of the icon */
height: 50px; /* Adjust size of the icon */
}

.zoom-modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
}

.zoomed-image {
max-width: 90%;
max-height: 90%;
border: 2px solid white;
border-radius: 8px;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
cursor: pointer;
}

.btn {
background-color: #6c757d;
color: white;
border: none;

cursor: pointer;
border-radius: 4px;

transition: background-color 0.3s ease;
}

.btn:hover {
background-color: #5a6268; /* A slightly darker shade for hover effect */
}
</style>



